import React from "react"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Jumbotron pageInfo={{className:"jumbotron__img-bg-pos-cc", title: "404", subtitle: "Page Not Found", backgroundImage: '/images/404-header.jpg' }} />
    <section>
      <Container>
        <Row>
          <Col>
            <h1>ERROR 404: PAGE NOT FOUND</h1>
            <p id="page-not-found-redirect">Redirecting in <span id="timeoutTimer"></span>...</p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default NotFoundPage
